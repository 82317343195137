<template>
  <el-dialog :title="title" :visible.sync="visible" width="60%" :before-close="cancel" v-if="visible">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="文件预览" name="0" v-loading="loading2">
        <div>
          <div style="width: 100%;height:calc(100vh - 320px)">
            <iframe v-if="!loading2"
              :src='this.$pdfPreviewUrl() + "onlinePreview?url=" + this.$Base64.encode(fileDetailsForm.url.replaceAll("\\", "/")) + "&mediaFlag=1"'
              frameborder="0" width="100%" height="100%"></iframe>
          </div>
          <!--图片-->
          <!-- <div v-if="fileType === 'image'">
                        <el-image style="width: 100%;" :src="fileDetailsForm.url" fit="contain"></el-image>
                    </div> -->
          <!--视频-->
          <!-- <div v-else-if="fileType === 'video'">
                        <video style="width: 100%;" controls :src=fileDetailsForm.url></video>
                    </div> -->
          <!--音频-->
          <!-- <div v-else-if="fileType === 'radio'">
                        <audio style="width: 100%;" controls :src="fileDetailsForm.url"></audio>
                    </div> -->
          <!--pdf-->
          <!-- <div v-else-if="fileType === 'pdf'">
                        <pdf :src="fileDetailsForm.url" :page="currentPage"
                             @num-pages="pageCount=$event"
                             @page-loaded="currentPage=$event"
                             @loaded="loadPdfHandler">
                        </pdf>
                        <p class="text_center m_b1">
                        <span @click="changePdfPage(0)">
                            <i class="el-icon-caret-left pointer" style="font-size: 20px"></i>
                        </span>
                            <span style="position: relative;bottom: 2px">{{currentPage}} / {{pageCount}}</span>
                            <span @click="changePdfPage(1)">
                            <i class="el-icon-caret-right pointer" style="font-size: 20px"></i>
                        </span>
                        </p>
                    </div> -->
          <!--word-->
          <!--                    <div v-else-if="fileType === 'word'">-->
          <!--                        <iframe :src='fileDetailsForm.url' width='100%' height='623px' frameborder='0'>This is an embedded-->
          <!--                            <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by-->
          <!--                            <a target='_blank' href='http://office.com/webapps'>Office Online</a>-->
          <!--                        </iframe>-->
          <!--                    </div>-->
          <!-- <el-empty v-else description="无法预览"></el-empty> -->
        </div>
        <div class="m_t1">
          <el-tag :key="tag" v-for="tag in dynamicTags" :disable-transitions="false" @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <!--                    <el-input-->
          <!--                            class="input-new-tag"-->
          <!--                            v-if="inputVisible"-->
          <!--                            v-model="inputValue"-->
          <!--                            ref="saveTagInput"-->
          <!--                            size="small"-->
          <!--                            @keyup.enter.native="handleInputConfirm"-->
          <!--                            @blur="handleInputConfirm"-->
          <!--                    >-->
          <!--                    </el-input>-->
          <el-autocomplete class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
            @keyup.enter.native="handleSelect()" :maxlength="50" :fetch-suggestions="querySearch" @select="handleSelect"
            @blur="inputBlur">
            <template slot-scope="{ item }">
              <el-tooltip placement="top">
                <div slot="content">{{ item.name }}</div>
                <div>{{ item.name }}</div>
              </el-tooltip>
            </template>
          </el-autocomplete>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
        </div>
        <div class="m_t1 text_center">
          <el-button size="small" @click="clickOpen">全屏预览</el-button>
          <el-button size="small" @click="print" type="primary">打印</el-button>
          <!--                @click="print"-->
          <el-button size="small" @click="downloadRow(fileDetailsForm)">下载</el-button>
          <!--                    <span v-if="type=='itemRetrieval'">-->
          <el-button size="small" icon="el-icon-star-on" type="danger"
            v-if="fileDetailsForm.collectionId && type == 'itemRetrieval'" @click="collection(1)"
            v-noMoreClick>已收藏</el-button>
          <el-button size="small" icon="el-icon-star-off"
            v-else-if="!fileDetailsForm.collectionId && type == 'itemRetrieval'" @click="collection(0)"
            v-noMoreClick>未收藏</el-button>
          <!--                    </span>-->
        </div>
      </el-tab-pane>
      <el-tab-pane label="文件详情" name="1">
        <el-form class="dialogForm" size="small" :model="fileDetailsForm" ref="fileDetailsForm" label-width="120px"
          disabled>
          <el-form-item :label="item.recordName + '：'" :prop="item.fieldEnname" v-for="(item, index) in config"
            :key="index">
            <el-input v-if="item.detailsShowType == '0'" v-model="fileDetailsForm[item.fieldEnname]"></el-input>
            <el-select
              v-if="item.detailsShowType == '1' && (item.fieldEnname == 'quanzongNo' || item.fieldEnname == 'exhibitionId')"
              v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" @change="manaList" placeholder="请选择">
              <el-option v-for="item in exhibitionList" :key="item.id" :label="item.exhibitionName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'uploadTarget'"
              v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
              <el-option v-for="item in catalogueList" :key="item.id" :label="item.directoryName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'fieldName8'"
              v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
              <el-option v-for="item in $dictUtils.getDictList('file_type')" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancel">取消</el-button>
      <!--                <el-button size="small" type="primary" @click="save()" v-noMoreClick>保存</el-button>-->
    </span>
  </el-dialog>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: { pdf },
  data() {
    return {
      visible: false,
      type: 'itemRetrieval',  // itemRetrieval条目检索、culturalList文物清单
      title: '',
      activeName: "0",
      // 文件详情
      fileDetailsForm: {
        id: "",
        url: "",
        fileFormat: "",
        collectionId: ""
      },
      catalogueList: [],//目录
      exhibitionList: [],//展览
      fileType: 'file',
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      config: [],//详情页配置
      // 标签
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      restaurants: [],
      filePath: "",
      loading2: false
    }
  },
  methods: {
    cancel() {
      this.visible = false
      this.$emit('refreshDataList')
    },
    clickOpen() {
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + this.$Base64.encode(this.fileDetailsForm.url.replaceAll('\\', '/')) + "&mediaFlag=0")
    },
    inputBlur() {
      setTimeout(() => {
        this.handleSelect();
      }, 1000)
      // this.inputVisible = false
    },
    init(row, type) {
      this.activeName = "0"
      this.type = type;
      this.loading2 = true;
      this.inputVisible = false
      this.$nextTick(() => {
        // if(this.type == 'culturalList') {
        //     this.activeName = '1'
        // } else {
        //     this.activeName = '0'
        // }
        this.exhibitionInAll();
        this.querySysOriginalDescriptionAll(row);//详情页
      })
    },
    //详情页配置
    querySysOriginalDescriptionAll(id) {
      let flag = 2
      if (this.type == "culturalList") {//条目、culturalList：清单
        flag = 3
      }
      let that = this;
      this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
        flag: flag,
        onDetails: 0
      }, 'get').then(data => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            that.$set(that.fileDetailsForm, v.fieldEnname, "")
          })
          this.fileCollectQueryById(id, flag);
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    //目录
    manaList() {
      this.$axios(this.api.zlzs.selectSysDirectory, {
        "exhibitionId": this.fileDetailsForm.quanzongNo
      }, 'get').then(data => {
        if (data.status) {
          this.catalogueList = data.data;
        }
      })
    },
    //文件信息
    fileCollectQueryById(id, flag) {
      let api = this.api.zlzs.queryByIdRetrieve
      if (this.type == "culturalList") {
        api = this.api.zlzs.culturalRelicsByIdRetrieve
      }
      this.$axios(api, {
        'id': id,
        'flag': flag,
        'onDetails': 0
      }, 'get').then(data => {
        if (data.status) {
          this.visible = true;
          if (this.type == "culturalList") {
            this.title = data.data.culturalName;
          } else {
            this.title = data.data.fileName;
          }
          this.fileDetailsForm = this.recover(this.fileDetailsForm, data.data)

          // this.fileDetailsForm.url=this.$getUrl()+this.fileDetailsForm.url
          this.filePath = JSON.parse(JSON.stringify(this.fileDetailsForm.url))
          if (this.type == "itemRetrieval") {
            this.addWatermark().then(data => {
              if (data) {
                this.fileDetailsForm.url = data
              } else {
                this.fileDetailsForm.url = this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("\\"), this.fileDetailsForm.url.length)
              }
            })
            // this.$getUrl()+data.substring(data.indexOf("\\"),data.length)
          }
          this.loading2 = false

          // this.fileDetailsForm.fileSize=(this.fileDetailsForm.fileSize/1024/1024).toFixed(2)
          // if(this.fileDetailsForm.quanzongNo==0){
          //     this.fileDetailsForm.quanzongNo = ""
          // }
          //原来的文件对象，用于判断是否修改，记录日志
          // this.fileDetailsForm2=JSON.parse(JSON.stringify(this.fileDetailsForm))
          if (this.type !== "culturalList") {
            this.manaList();//根据展览id查询所有目录
          }
          this.getFileSrc()
          this.labelIndexQueryAll()
          this.labelIndexAll()
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    //展览
    exhibitionInAll() {
      this.$axios(this.api.zlzs.exhibitionInall, {}, 'get').then(data => {
        if (data.status) {
          this.exhibitionList = data.data;
        }
      })
    },
    //文件标签
    labelIndexQueryAll() {
      this.dynamicTags = []
      let that = this;
      this.$axios(this.api.zlzs.labelIndexQueryAll, { fileId: this.fileDetailsForm.id }, 'get').then(data => {
        if (data.status && data.data) {
          data.data.forEach(function (v) {
            that.dynamicTags.unshift(v.name)
          })
        }
      })
    },
    //打印
    print() {
      let log = {
        operModular: "5",//模块
        operType: "16",//类型
        operTerm: "",//内容
        operSystem: "1",//结果:失败
      }
      if (this.type == 'itemRetrieval') {
        log.operTerm = this.fileDetailsForm.fileName
      } else {
        log.operModular = "4"
        log.operTerm = this.fileDetailsForm.culturalName
      }
      if (this.fileType == 'pdf' || this.fileType == 'image') {
        this.$print({
          printable: this.fileDetailsForm.url,
          type: this.fileType,
          header: null,
          targetStyles: ['*'],
          style: "@page {margin:0 10mm}"
        })
        log.operSystem = "0"
      } else {
        this.$message.error("仅支持图片和pdf格式文件！")
      }
    },
    addWatermark() {
      let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
      let sourceFile = JSON.parse(sessionStorage.getItem('userInfo')).id + this.filePath.substring(this.filePath.lastIndexOf("/"), this.filePath.length)
      return new Promise((resolve, reject) => {
        if (this.fileDetailsForm.fileFormat == "pdf" || this.fileDetailsForm.fileFormat == "PDF") {
          this.$axios("zyd-catalogue/pdf/addWatermark", {
            "target": this.filePath,
            "sourceFile": sourceFile,
            "watermark": watermark
          }, 'post').then(data => {
            if (data.status) {
              resolve(data.data)
            } else {
              resolve("")
            }
          })
        } else {
          resolve("")
        }
      })
    },
    // 下载
    downloadRow(row) {
      let log = {
        operModular: "5",//模块
        operType: "19",//类型
        operTerm: "",//内容
        operSystem: "1",//结果:失败
      }
      this.addWatermark(row.url).then(res => {
        let url = ""
        if (res) {
          url = res
        } else {
          url = row.url;
        }
        const a = document.createElement('a')
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          if (this.type == 'itemRetrieval') {
            a.download = row.fileName + "." + row.fileFormat // 下载文件的名字
            log.operTerm = row.fileName
          } else {
            log.operModular = "4"
            a.download = row.culturalName // 下载文件的名字
            log.operTerm = row.culturalName
          }
          document.body.appendChild(a)
          a.click()
          log.operSystem = "0"
        })
      })
    },
    collection(type) {
      if (type == 0) {
        this.$axios(this.api.zlzs.MyCollectionSave, {
          fileIds: this.fileDetailsForm.id
        }, 'post').then(data => {
          if (data.status) {
            this.$message.success("收藏成功")
            this.fileDetailsForm.collectionId = "1"
          } else {
            this.$message.error("收藏失败")
          }
        })
      } else {
        this.$axios(this.api.zlzs.MyCollectionRemoveById, {
          ids: this.fileDetailsForm.collectionId
        }, 'delete').then(data => {
          if (data.status) {
            this.$message.success("取消收藏成功")
            this.fileDetailsForm.collectionId = ""
          } else {
            this.$message.error("取消收藏失败")
          }
        })
      }
      // this.querySysOriginalDescriptionAll(this.fileDetailsForm.id)
    },
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1 // 加载的时候先加载第一页
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++
      }
    },
    getFileSrc() {
      this.fileType = "file"
      let fileType = this.fileDetailsForm.fileFormat
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'bmp' || fileType === 'gif'
        || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'BMP' || fileType === 'GIF') {
        this.fileType = 'image'
      } else if (fileType === 'pdf' || fileType === 'PDF') {
        this.fileType = 'pdf'
      } else if (fileType === 'doc' || fileType === 'docx') {
        this.fileType = 'word'
        // this.fileDetailsForm.url = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.fileDetailsForm.url
      } else if (fileType === 'mp4' || fileType === 'm2v' || fileType === 'mkv') {
        this.fileType = 'video'
      } else if (fileType === 'mp3' || fileType === 'wav' || fileType === 'wmv') {
        this.fileType = 'radio'
      }
    },
    handleClick(tab, event) {
    },
    // 标签
    handleClose(tag) {
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    labelIndexAll() {
      let that = this;
      this.$axios(this.api.zlzs.labelIndexQueryAll, {}, 'get').then(data => {
        if (data.status && data.data) {
          this.restaurants = data.data
          this.restaurants.forEach(function (v) {
            that.$set(v, 'value', v.name)
          })
        }
      })
    },
    handleSelect(item) {
      let inputValue = ''
      if (item) {
        inputValue = item.name.trim()
      } else {
        inputValue = this.inputValue.trim();
      }
      if (inputValue) {
        this.$axios(this.api.zlzs.labelIndexSave, {
          createId: this.fileDetailsForm.id,
          name: inputValue
        }, 'post').then(data => {
          if (data.status) {
            this.$message.success("提交成功")
            this.labelIndexQueryAll();
            this.labelIndexAll()
          } else {
            this.$message.error("标签已存在")
          }
        })
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  }
}
</script>

<style scoped>
.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
}

/*.el-dialog .el-dialog__header{*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*    text-overflow: ellipsis;*/
/*}*/
</style>
