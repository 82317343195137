<template>
    <div>
        <el-dialog title="日志" width="90%" :visible.sync="visibleLog" :close-on-click-modal="false">
            <div class="h100">
                <el-row :gutter="10" class="m_t1 m_b1">
                    <el-col :span="5">
                        <el-input size="small" v-model="srhStr" placeholder="请输入操作内容/操作人"
                                  clearable maxlength="66"></el-input>
                    </el-col>
                    <el-col :span="10">
                        <el-button type="primary" @click="searchRefreshList(modular)" size="small" icon="el-icon-search">查询
                        </el-button>
                        <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                    </el-col>
                </el-row>
                <el-table
                        :data="dataList"
                        v-loading = "loading"
                        size="small"
                        height="calc(100vh - 280px)"
                        class="table">
                    <el-table-column type="selection" width="50" />
                    <el-table-column prop="createTime" label="操作时间" sortable/>
                    <el-table-column prop="operType" label="操作类型" sortable show-overflow-tooltip >
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("log_ope_type", scope.row.operType)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="operTerm" label="操作内容" sortable show-overflow-tooltip />
                    <el-table-column prop="operSystem" label="操作结果" sortable show-overflow-tooltip >
                        <template slot-scope="scope">
                            {{$dictUtils.getDictLabel("log_ope_result", scope.row.operSystem)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="operName" label="操作人" sortable show-overflow-tooltip />
                    <el-table-column prop="operIp" label="操作IP" sortable show-overflow-tooltip />
                </el-table>
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visibleLog: false,
                srhStr: '',
                dataList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                modular:"",
            }
        },
        methods: {
            // 查询
            init(modular) {
                this.visibleLog = true;
                this.modular=modular
                this.refreshList(this.modular)
            },
            searchRefreshList(modular){
                this.pageNo = 1
                this.refreshList(modular)
            },
            // 查询
            refreshList(modular) {
                this.loading = true
                this.$axios(this.api.zlzs.logFindList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    "modular":modular,
                    "search":this.srhStr
                }, 'get').then(data => {
                    console.log(data);
                    if (data && data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 查看
            view() {

            },
            // 删除
            del (id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.auth, {'ids': id}, 'delete').then(data => {

                    })
                })
            },
            // 重置
            resetSearch() {
                this.srhStr = "";
                this.pageNo = 1;
                this.refreshList(this.modular)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.refreshList(this.modular)
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.refreshList(this.modular)
            },
        }
    }
</script>

<style scoped>

</style>
